






























import { defineComponent } from "@vue/composition-api";
import LogoutButton from "@/components/LogoutButton.vue";
import UserManagement from "@/views/admin/UserManagement.vue";
import ProductManagement from "@/views/admin/ProductManagement.vue";
import SavedToast from "@/components/Toast/SavedToast.vue";
import DeleteToast from "@/components/Toast/DeleteToast.vue";
import SaveFailedToast from "@/components/Toast/SaveFailedToast.vue";

export default defineComponent({
    name: "AdminDashboard",
    components: { SaveFailedToast, DeleteToast, SavedToast, ProductManagement, UserManagement, LogoutButton }
})
